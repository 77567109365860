import React, { useContext, useState } from "react";
import "../../styles/enquire/EnquirePage.scss";
import { useForm, Controller } from "react-hook-form";
import { enquireValidationSchema } from "../../models/ValidationSchema";
import { Trans, useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { GlobalContext } from "../../App";
import { emailInputField, phoneInputField } from "../../data/InputFields";
import { fetchEnquireInfo } from "../../api/apiManagement";
import { EnquireContext } from "./Enquire";

const EnquirePage = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    getValues,
  } = useForm({ resolver: yupResolver(enquireValidationSchema) });

  const { isLoading, setIsLoading } = useContext(GlobalContext);
  const { userData, setUserData, setCurrentStep, currentStep } =
    useContext(EnquireContext);
  const [apiError, setApiError] = useState(0);

  const onSubmitData = (d) => {
    setApiError(0);
    setIsLoading(true);
    fetchEnquireInfo(d.email, d.phone)
      .then((res) => {
        const currentStatus = res.data.status;
        console.log(currentStatus);
        console.log(res.data.data);

        console.log(userData);
        setApiError(0);
        if (currentStatus === 200) {
          setUserData(res.data.data);
          setCurrentStep(1);
        } else if (currentStatus === 203) {
          setApiError(3);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  return (
    <div className="enquire-page-container">
      <div className="enquire-login">
        <Trans i18nKey="enquire:enquire_message" />
      </div>
      <div className="enquire-container">
        {/* {Email} */}
        <div className="input-container">
          <div className="label">
            <Trans i18nKey="enquire:enquire_mail" />
          </div>
          <input
            className="form-input"
            type={emailInputField.type}
            {...register(emailInputField.value, {
              required: true,
              pattern: emailInputField.pattern,
            })}
          ></input>
          <div className="input-error-message">
            {errors[emailInputField.value] &&
            errors[emailInputField.value].message === "fieldRequired" ? (
              <Trans i18nKey="enquire:enquire_mail_error" />
            ) : (
              <></>
            )}
          </div>
        </div>
        {/* {Phone} */}
        <div className="input-container">
          <div className="label">
            <Trans i18nKey="enquire:enquire_mobile" />
          </div>
          <input
            className="form-input"
            type={phoneInputField.type}
            minLength={phoneInputField.minLength}
            maxLength={phoneInputField.maxLength}
            {...register(phoneInputField.value, {
              required: true,
              minLength: phoneInputField.minLength,
              maxLength: phoneInputField.maxLength,
              pattern: phoneInputField.pattern,
            })}
          ></input>
          <div className="input-error-message">
            {errors[phoneInputField.value] &&
            errors[phoneInputField.value].message === "fieldRequired" ? (
              <Trans i18nKey="enquire:enquire_mobile_error" />
            ) : (
              <></>
            )}
            {apiError === 3 ? <Trans i18nKey="enquire:enquire_error" /> : <></>}
          </div>
        </div>
        <button
          className="input-button"
          type="button"
          onClick={handleSubmit(onSubmitData)}
        >
          <Trans i18nKey="enquire:enquire_button" />
        </button>
      </div>
    </div>
  );
};

export default EnquirePage;
