import axios from "axios";
axios.defaults.baseURL =
  "http://ec2-52-77-251-15.ap-southeast-1.compute.amazonaws.com:8080/klook";

const configHeaders = {
  headers: {
    "Cache-Control": "no-cache",
    "Content‑Type": "application/x‑www‑form‑urlencoded",
    "Access-Control-Request-Headers": "*",
    Authorization: "Bearer " + "yourJWTToken",
  },
};

const connectApi = async ({ method = "GET", url, payload }) => {
  return await axios({
    method,
    origin: "*",
    url,
    data: payload,
    withCredentials: false,
    responseType: "json",
  });
};

const baseURL = {
  fetchPromotionCode: "/GetCouponCode",
  submitForm: "/KlookRegistration",
  fetchEnquireInfo: "/GetRegistrationInfo",
  checkDuplicatedReg: "/CheckDuplicatedReg",
};

export const fetchPromotionCode = async (code) => {
  const config = {
    method: "GET",
    url: baseURL.fetchPromotionCode + `?code=${code}`,
  };
  return await connectApi(config);
};

export const submitForm = async (payload) => {
  const config = {
    method: "POST",
    url: baseURL.submitForm,
    payload,
  };
  return await connectApi(config);
};

export const fetchEnquireInfo = async (email, mobile) => {
  const config = {
    method: "GET",
    url: baseURL.fetchEnquireInfo + `?email=${email}&mobile=${mobile}`,
  };
  return await connectApi(config);
};

export const checkDuplicatedReg = async (email, mobile) => {
  const config = {
    method: "GET",
    url: baseURL.checkDuplicatedReg + `?email=${email}&mobile=${mobile}`,
  };
  return await connectApi(config);
};
