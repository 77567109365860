import React, { useState, useContext, useEffect } from "react";
import "../../styles/form/QuestionPage.scss";
import { useForm, Controller } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { GlobalContext } from "../../App";
import { FormContext } from "./Form";
import { yupResolver } from "@hookform/resolvers/yup";
import { tncValidationSchema } from "../../models/ValidationSchema";
import { questionInputField } from "../../data/InputFields";
import { submitForm } from "../../api/apiManagement";

const QuestionPage = () => {
  const { t, i18n } = useTranslation();
  const {
    register,
    unregister,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    getValues,
  } = useForm({ resolver: yupResolver(tncValidationSchema) });
  const { isLoading, setIsLoading } = useContext(GlobalContext);
  const { userData, setUserData, setCurrentStep, currentStep } =
    useContext(FormContext);
  const [apiError, setApiError] = useState(0);
  const [canSubmit, setCanSubmit] = useState(false);

  const generateQuestionInputFields = (number) => {
    const inputFields = [];
    let questionLength;
    number === 1 ? (questionLength = 10) : (questionLength = 6);
    for (let i = 0; i < questionLength; i++) {
      // console.log(i);
      inputFields.push(
        <div className="question-image-container" key={i}>
          <input
            {...register(`${questionInputField[i].value}${number}`)}
            type="checkbox"
            value="1"
            id={`${questionInputField[i].value}${number}`}
          ></input>
          <label
            className="question-image-label-container"
            htmlFor={`${questionInputField[i].value}${number}`}
          >
            {number === 1 ? (
              <img
                className="question_image"
                src={`/assets/img/i_${i}.png`}
                alt={`${[i]}`}
              ></img>
            ) : (
              <img
                className="question_image"
                src={`/assets/img/b_${i}.png`}
                alt={`${[i]}`}
              ></img>
            )}
          </label>{" "}
          <div className="label">
            {/* {questionInputField[i].label} */}
            {number === 1 ? (
              <Trans i18nKey={`form:form_i_${i}`} />
            ) : (
              <Trans i18nKey={`form:form_b_${i}`} />
            )}
          </div>
        </div>
      );
    }
    return inputFields;
  };

  useEffect(() => {
    console.log(userData);
  }, [userData]);

  const changeFalseToZero = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] === false) {
        obj[key] = "0";
      }
    }
    return obj;
  };

  const convertObjectToString = (obj) => {
    let question1 = "";
    let question2 = "";

    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        const keyValueString = `${key}:${value}`;

        if (key.endsWith("1")) {
          question1 += keyValueString + ",";
        } else if (key.endsWith("2")) {
          question2 += keyValueString + ",";
        }
      }
    }
    // Remove trailing comma from the strings
    question1 = question1.slice(0, -1);
    question2 = question2.slice(0, -1);

    // Create final objects
    const result = {
      question_1: question1,
      question_2: question2,
    };

    return result;
  };

  const onSubmitData = (d) => {
    setApiError(0);
    let result = changeFalseToZero(d);

    console.log(result);
    let questionDataFormat = convertObjectToString(result);
    console.log(questionDataFormat);
    setIsLoading(true);

    submitForm({
      ...userData,
      question_1: questionDataFormat.question_1,
      question_2: questionDataFormat.question_2,
    })
      .then((res) => {
        const currentStatus = res.data.data;
        console.log(currentStatus);
        // 200 : reg success
        // 201 : code not exist
        // 202 : code used
        // 203 : email and mobile duplicated
        if (currentStatus === 200) {
          setCurrentStep(4);
        } else if (currentStatus === 201) {
          setApiError(1);
        } else if (currentStatus === 202) {
          setApiError(2);
        } else if (currentStatus === 203) {
          setApiError(3);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  return (
    <div className="question-page-container">
      <div className="question-text">
        <Trans i18nKey="form:fill_text" />
        {userData?.member_type === 1 ? (
          <Trans i18nKey="form:JoyKreator" />
        ) : (
          <Trans i18nKey="form:businessKreator" />
        )}
      </div>
      <div className="question-text-container">
        <div className="question-text">
          <Trans i18nKey="form:question_interest" />
          <div className="question-container">
            {generateQuestionInputFields(1)}
          </div>
        </div>
      </div>
      <div className="question-text-container">
        <div className="question-text">
          <Trans i18nKey="form:question_business" />
          <div className="question-container-2">
            {generateQuestionInputFields(2)}
          </div>
        </div>
      </div>
      <div>
        <div className="tnc-container">
          <input
            className="input-checkbox"
            type="checkbox"
            {...register("tnc", {
              required: true,
            })}
          />
          <span className="tnc">
            <div dangerouslySetInnerHTML={{ __html: t("form:tnc") }} />
          </span>
        </div>
        <span className="error-message">
          {errors.tnc && errors.tnc.message === "fieldRequired" ? (
            <Trans i18nKey="form:tnc_error" />
          ) : (
            <></>
          )}
        </span>
      </div>
      <br></br>
      <span className="error-message">
        {apiError === 1 ? <Trans i18nKey="form:api_error_1" /> : <></>}
        {apiError === 2 ? <Trans i18nKey="form:api_error_2" /> : <></>}
        {apiError === 3 ? <Trans i18nKey="form:api_error_3" /> : <></>}
      </span>
      <button
        className="input-button"
        type="button"
        onClick={handleSubmit(onSubmitData)}
      >
        提交
      </button>
    </div>
  );
};

export default QuestionPage;
