import React, { useContext, useState } from "react";
import "../../styles/form/InvitationCodePage.scss";
import { useForm } from "react-hook-form";
import { codeInputField } from "../../data/InputFields";
import { Trans, useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { codeValidationSchema } from "../../models/ValidationSchema";
import { GlobalContext } from "../../App";
import { FormContext } from "./Form";
import { fetchPromotionCode } from "../../api/apiManagement";
import Enquire from "../../pages/enquire/Enquire";

const InvitationCodePage = () => {
  const { t, i18n } = useTranslation();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    getValues,
  } = useForm({ resolver: yupResolver(codeValidationSchema) });
  const { isLoading, setIsLoading } = useContext(GlobalContext);
  const { userData, setUserData, setCurrentStep, currentStep } =
    useContext(FormContext);

  const [apiError, setApiError] = useState(0);
  const [goEnquire, setGoEnquire] = useState(false);

  const onSubmitData = (d) => {
    console.log(d.code);
    setApiError(0);
    // //set userData
    setIsLoading(true);
    fetchPromotionCode(d.code)
      .then((res) => {
        const currentStatus = res.data.status;
        console.log(currentStatus);
        let fetchData = res.data.data;
        if (currentStatus === 200) {
          if (fetchData.email != null) {
            setUserData({
              ...userData,
              code: d.code,
              name: fetchData.name,
              email: fetchData.email,
              phone: fetchData.mobile,
              sex: fetchData.gender,
              ig_name: fetchData.instagram_id,
              ig_url: fetchData.instagram_url,
            });
          } else {
            setUserData({
              ...userData,
              code: d.code,
            });
          }
          setCurrentStep(1);
        } else if (currentStatus === 201) {
          setApiError(1);
        } else if (currentStatus === 202) {
          setApiError(2);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  return (
    <div className="invitation-code-page-container">
      {!goEnquire ? (
        <>
          <div className="image-button-container">
            <img
              className="member-button"
              alt="member-button"
              onClick={() => setGoEnquire(true)}
              src="./assets/img/member.png"
            />
          </div>
          <div className="input-background">
            <div className="input-container ">
              <div className="image-button-container">
                <img
                  className="code-button"
                  alt="code-button"
                  onClick={handleSubmit(onSubmitData)}
                  src="./assets/img/code_button.png"
                />
              </div>
              <input
                className="input-code"
                type={codeInputField.type}
                maxLength={codeInputField.maxLength}
                minLength={codeInputField.minLength}
                onClick={() => setApiError(0)}
                {...register(codeInputField.value, {
                  required: true,
                  maxLength: codeInputField.maxLength,
                  minLength: codeInputField.minLength,
                })}
              ></input>{" "}
              <div className="input-text label">
                <Trans i18nKey="form:invitation_code_input_text" />
              </div>
              <span className="error-message">
                {errors[codeInputField.value] &&
                errors[codeInputField.value].message === "fieldRequired" ? (
                  <Trans i18nKey="form:invitation_code_input_error" />
                ) : (
                  <></>
                )}
                {apiError === 1 ? <Trans i18nKey="form:api_error_1" /> : <></>}
                {apiError === 2 ? <Trans i18nKey="form:api_error_2" /> : <></>}
              </span>
            </div>{" "}
            <img
              className="banner"
              alt="banner"
              src="./assets/img/banner.png"
            />
          </div>
          {/* <button className="input-button button">
            <Trans i18nKey="form:invitation_code_input_button" />
          </button> */}
          {/* <div className="enquire-text" onClick={() => setGoEnquire(true)}>
            <Trans i18nKey="form:enquire" />
          </div> */}
        </>
      ) : (
        <>
          <Enquire />{" "}
          <div className="enquire-text" onClick={() => setGoEnquire(false)}>
            <Trans i18nKey="form:back" />
          </div>
        </>
      )}
    </div>
  );
};

export default InvitationCodePage;
